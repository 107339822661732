.footer_site{
    background-color: #1f1f1f;
    min-height: 300px;
    color: #bebebe;

    padding: 60px 0;

    h5{
        font-size: 16px;
        font-weight: normal;
        text-transform: uppercase;
        margin-bottom: 8px;
    }

    p{
        margin-bottom: 8px;
        font-size: 14px;
        color: #fff;
        a{
            color: inherit;
            text-decoration: none;
            font-weight: normal;
        }

        &.social{
            a{
                font-size: 22px;
                margin-right: 8px;
            }
        }
    }

    input.form-control{
        border-radius: 0;
        background-color: black;
        border-color: black;
        color: white;
        &::placeholder{
            color: #bebebe;
        }
        &:focus{
            border-color: grey;
            outline: none;
            box-shadow: none;
        }
    }
}
