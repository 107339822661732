.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 1000ms ease-in-out;
}

.fade-exit {
    opacity: 0;
    display: none;
}

.fade-exit-active {
    opacity: 0;
    display: none;
}

//
//.fade-enter {
//    opacity: 0;
//    position: absolute;
//    z-index: 1;
//    transform: translateX(100%);
//}
//
//.fade-enter-active {
//    opacity: 1;
//    position: relative;
//    z-index: 1;
//    transform: translateX(0%);
//    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
//}
//
//.fade-exit {
//    opacity: 1;
//    position: absolute;
//    z-index: 0;
//    transform: translateX(0%);
//}
//
//.fade-exit-active {
//    opacity: 0;
//    position: absolute;
//    z-index: 0;
//    transform: translateX(-100%);
//    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
//}
//
