.has-date-picker{
    & > div{
        display: flex;
        justify-content: center;
    }
}

.react-datepicker__month-container{
    min-width: 320px;
}
.react-datepicker__month{
    margin: 0;
}
.react-datepicker__header {
    text-align: center;
    background-color: #fff;
    border-bottom: 0;
    border-top-left-radius: 0.3rem;
    padding: 8px 0;
    position: relative;
}
.react-datepicker__current-month{
    margin-bottom: 8px;
    text-align: left;
    padding-left: 16px
}
.react-datepicker__navigation--previous{
    right: 30px;
    left: auto;
}
.react-datepicker__navigation{
    top: 10px
}


.react-datepicker__week,
.react-datepicker__day-names{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
}
.react-datepicker__day-names{
    margin-top: 8px;

}
.react-datepicker {
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid transparent;
    border-radius: 0;
    display: inline-block;
    position: relative;
}


.react-datepicker__day--outside-month{
    background: #80808012;
}
