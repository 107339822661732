.suit_builder_container{
    display: flex;
    height: calc(var(--vh, 1vh) * 100 - var(--header-mobile));
    padding: 0;
    overflow: hidden;



    .main-area{
        min-width: calc(100vw - 420px);
        display: flex;
        align-items: center;
        justify-content: center;
        background: #EFEFEF;

        .slick-prev:before, .slick-next:before {
            opacity: .75;
            color: #878282;
        }

        .suit-builder-view{
            max-width: calc(100vw - 420px - 80px);
            min-width: 375px;
            margin: auto;

            .viewer{
                display: flex;
                align-items: center;
                justify-content: center;

                img{
                    margin: auto;
                    width: calc( var(--vh,1vh) * 80.9575 - var(--header-mobile) );
                }
            }
        }
    }

    .sidebar-area{
        overflow-x: hidden;
        width: 420px;
        .Info-sidebar{
            transition: left 0.4s;
            right: -100%;
            position: absolute;
            top: 56px;
            bottom: 0;
            background: #ffffff;
            width: 420px;
            transition: left 0.4s;
            visibility: hidden;
        }
        .Info-sidebar.active {
            display: block;
            //height: 100vh;
            position: absolute;
            top: 56px;
            bottom: 0;
            left: auto;
            right: 0;
            background: #ffffff;
            width: 420px;
            transition: left 0.4s;
            visibility: visible;
            @media only screen and (max-width: 992px){
                width: 100% !important;
                top: 40px;
            }
            .crossBtn{
                width: 25px;
                height: 25px;

            }
        }

        .sidebar-tabs-list{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;
            width: 100%;
            background-color: #fff;
            padding: 0;

            scrollbar-width: none;
            -ms-overflow-style: none;

            &::-webkit-scrollbar {
                display: none;
            }

            li{
                width: 200px;
                background-color: #fff;
                border: none;
                color:#000000;
                margin-right: 10px;
                border-radius: 0;
                font-size: 13px;
                padding: 10px 20px;
                margin-bottom: 1px;
                outline: none;
                &.react-tabs__tab--selected{
                    border: none;
                    border-bottom: 2px solid black;
                }
                &.react-tabs__tab:focus:after{
                    content: none !important;
                }
            }
        }

        .tab{

            .tab-heading{

            }

            .tab-column{
                overflow-y: scroll;
                height: calc(100vh - 300px);
            }

        }

        .jacket-card{
            padding: 18px 18px;
            border-radius: 6px;
            border: 1px solid #cecece;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            &.selected{
                border-color: #7e7e7e;
            }
            &:hover{
                border-color: #7e7e7e;
            }
            .title{
                font-weight: 500;
                color: black;
            }
            .option{
                color: grey;
                font-size: 13px;
            }
        }



        .fabric-tab{
            //padding: 0;
            .fabrics-selector{
                .fabric-card{
                    padding: 6px;
                    margin-bottom: 30px;
                    border: 1px solid transparent;
                    border-radius: 4px;
                    &.selected{
                        border-color: #7e7e7e;
                    }
                    .fabric-image{
                        height: 140px;
                        width: 100%;

                        img{
                            height: 100%;
                            width: 100%;
                            border-radius: 6px;
                            object-fit: cover;
                        }
                        .infoIcon{
                            pointer-events: unset;
                            img{
                                width: 20px;
                                height: 20px;
                                object-fit: contain;
                                filter: invert(1);
                                cursor: pointer;
                            }
                        }
                    }
                    .details{
                        padding: 0 10px;
                    }
                }
            }
        }


        .sidebar-footer{
            height: 103px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-top: 1px solid #bbbbbb;
            margin-top: 8px;
            z-index: 9;

            button{
                min-width: 48%;
                margin: 2px;
            }
            span{
                font-size: 12px;
            }
        }
    }

    .jacket-viewer{
        background: #EFEFEF;
        .holder{
            max-width: 600px;
            margin: auto;
            position: relative;
            width: calc( var(--vh,1vh) * 68.9575 - var(--header-mobile) );
            min-height: 600px;

            img{
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;

            }

            &.overlay{
                &:before{
                    content: "Not Seleted Yet";
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    font-weight: lighter;
                    color: dimgrey;
                    z-index: 99;
                    background: #EFEFEF;
                    opacity: 0.9;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }

}




//################ Slider LEFT
.slider-left{
    position: absolute;
    width: 420px;
    height: calc(100% - 0);
    background: rgba(255, 255, 255, 0.96);
    backdrop-filter: blur(2px);
    transition: all 300ms ease-in-out;
    z-index: 1;
    top: var(--header-mobile);
    right: -100%;
    padding: 10px;
    &.active{
        right: 0;
    }

    .sidebar-footer{
        height: 103px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #bbbbbb;

        button{
            min-width: 48%;
            margin: 2px;
        }
        span{
            font-size: 12px;
        }
    }

    .content{
        overflow-y: scroll;
        height: calc(100vh - 166px);
    }

    .tab-column.inner{
        height: calc(100vh - 240px) !important;
    }
    .inner-selection{
        padding: 18px 18px;
        border-radius: 6px;
        border: 1px solid #cecece;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        cursor: pointer;
        &.selected{
            border-color: #7e7e7e;
        }
        &:hover{
            border-color: #7e7e7e;
        }
        .title{
            font-weight: 500;
            color: black;
            margin-top: 20px;
        }
        .option{
            color: grey;
            font-size: 13px;
        }
    }




}


//####### INDEPENDENT CARDS
.add-item-card{
    padding: 18px 18px;
    border-radius: 6px;
    border: 1px solid #cecece;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    cursor: pointer;


    &.selected{
        border-color: #7e7e7e;
    }
    &:hover{
        border-color: #7e7e7e;
    }
    .title{
        font-weight: 500;
        color: black;
    }
    .option{
        color: grey;
        font-size: 13px;
    }
}


//
.text-center.inner-selection{
    img{
        max-width: 120px;
    }
}

// Style override
.jacket-card{
    text-transform: capitalize;
}
@media only screen and (max-width:992px){
    body .app header .navbar{
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 9 !important;
    }
    .suit_builder_container .main-area .suit-builder-view {
        max-width: calc(100vw);
        min-width: 375px;
        margin: 50px auto 0 auto;
        position: fixed;
        top: 0;
        z-index: 9 !important;
    }
    .suit_builder_container .jacket-viewer .holder {
        max-width: 600px;
        margin: auto;
        position: relative;
        width: calc( var(--vh,1vh) * 68.9575 - var(--header-mobile));
        min-height: 380px;
    }

    .suit_builder_container .main-area .suit-builder-view .viewer img {
        margin:0 auto;
        margin-top: 10px !important;
        min-height: 360px;
        max-height: 360px;
        max-width: 80% !important;
    }
    .suit_builder_container .main-area {
        min-width: calc(100vw);
        display: flex;
        align-items: center;
        justify-content: center;
        background: #EFEFEF;
    }
.suit_builder_container .sidebar-area {
    width: 100% !important;
    position: absolute;
    top: 55%;
    background: #fff;
}
    .suit-builder-view .slick-slider.slick-initialized .slick-prev, .slick-next{
        z-index: 9999 !important;
    }
    .suit-builder-view .slick-slider.slick-initialized .slick-prev{
        left: 0 !important;
        z-index: 999 !important;
    }
    .suit-builder-view .slick-slider.slick-initialized .slick-next{
        right: 0 !important;
        z-index: 999 !important;
    }
    .slider-left{
        position: absolute;
        width: 100%;
        //height: calc(100% - var(--header-mobile));
        background: rgba(255, 255, 255, 0.96);
        backdrop-filter: blur(2px);
        transition: all 300ms ease-in-out;
        z-index: 1;
        top: var(--header-mobile);
        right: -100%;
        padding: 10px;
        &.active{
            right: 0;
        }

        .sidebar-footer{
            height: 103px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-top: 1px solid #bbbbbb;

            button{
                min-width: 48%;
                margin: 2px;
            }
            span{
                font-size: 12px;
            }
        }

        .content{
            overflow-y: scroll;
            height: calc(100vh - 166px);
        }

        .tab-column.inner{
            height: calc(100vh - 240px) !important;
        }
        .inner-selection{
            padding: 18px 18px;
            border-radius: 6px;
            border: 1px solid #cecece;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            cursor: pointer;
            &.selected{
                border-color: #7e7e7e;
            }
            &:hover{
                border-color: #7e7e7e;
            }
            .title{
                font-weight: 500;
                color: black;
                margin-top: 20px;
            }
            .option{
                color: grey;
                font-size: 13px;
            }
        }




    }
    .suit_builder_container .sidebar-area .tab .tab-column{
        overflow-y:unset !important;
        height: unset !important;
    }
    .stepper-form-wrapper-main .builder-title {
        text-align: center;
        font-size: 36px;
        font-weight: normal;
        padding-top: 5rem;
        margin-bottom: 16px;
        margin-top: 20px;
    }
}



@media only screen and (max-width:400px){
    .suit_builder_container .sidebar-area {
        width: 100% !important;
        position: absolute;
        top: 45%;
        background: #fff;
    }
}
@media only screen and (max-width:992px) {
    .suit_builder_container .sidebar-area {
        width: 100% !important;
        position: absolute;
        top: 60%;
        background: #fff;
    }
    .sidebar-footer{
        padding: 4px 0;
        height: unset !important;
        position: fixed;
        width: 100%;
        bottom: 0;
        background-color: #ffffff;
    }
    .sidebar-area .react-tabs{
        margin: 4rem 0;
        padding:0 0 6rem 0;
    }
    .main-area{
        max-height: 280px !important;
    }
    .suit-builder-view{
        margin: 83px auto 0 auto !important;
    }
    .sidebar-tabs-list {
        top: 2.8rem;
        z-index: 9;
        position: fixed;
    }
    .suit-builder-view .slick-dots {
        position: absolute;
        bottom: 0px;
        left: 0 !important;
        display: block;
        width: unset !important;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;
        min-height: 30px;
        li{
            margin:0 !important;
            width: 15px  !important;
        }

    }



}

